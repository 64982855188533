import { JSX, useEffect } from 'react'

import { useState } from 'react'
import { useMutation } from '@apollo/client'

import Loader from '../../Loader/Loader'
import { Plan, UsersMutations } from '../../../__generated__/graphql'
import { usersUpdateMe } from '../../../graphql/mutations/users-update-me'
import { store } from '../../../store/store'
import { AuthStepName } from '../../../types/auth-step-name.enum'

import { DidYouKnow } from './DidYouKnow'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { channelIdToPlanId } from '../../../util/channel-id-to-plan-id'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'

export interface EnhancedAuthNameProps {
  setAuthStep: (step: AuthStepName.picture) => void
  plan: Plan | null
}

export function EnhancedAuthName({ setAuthStep, plan }: EnhancedAuthNameProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const routeParams = useParams()
  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)
  const location = useLocation()
  useEffect(() => {
    createMixpanelEvent(MixpanelEvent.viewSignUpAddName, {
      pathname: location.pathname,
      platform: 'web',
      planId: planId ?? '',
    })
  }, [planId, location])

  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [placeholderFirstName, setPlaceholderFirstName] = useState('first name')
  const [placeholderLastName] = useState('last name')
  // const [placeholderLastName, setPlaceholderLastName] = useState('Last Name')
  const [isLoading, setIsLoading] = useState(false)

  const onFirstNameChange = (e: any) => {
    setUserFirstName(e.target.value)
  }

  const onLastNameChange = (e: any) => {
    setUserLastName(e.target.value)
  }

  const [updateMeMutation] = useMutation<{ users: UsersMutations }>(usersUpdateMe, {
    variables: { firstName: userFirstName, lastName: userLastName },
  })

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (!userFirstName) {
      setPlaceholderFirstName('Please enter your first name')
      return
    }
    // note - disabled this check as it's not required by the bkn
    // if (!userLastName) {
    //   setPlaceholderLastName('Please enter your last name')
    //   return
    // }
    setIsLoading(true)

    try {
      const response = await updateMeMutation()
      const user = response?.data?.users.updateMe
      if (user != null) {
        store.dispatch({ type: 'user/upsert', user })
        setAuthStep(AuthStepName.picture)
      }
    } catch (_error) {
      // TODO: Handle user auth failure
    }
    setIsLoading(false)
  }

  return (
    <article className="EnhancedAuth">
      <header className="EnhancedAuth__header">
        {plan && <h1>let {plan.ownerUser.firstName} know who you are</h1>}
      </header>
      <div className="EnhancedAuth__main">
        <form className="EnhancedAuth__form" onSubmit={onSubmit}>
          <div className="EnhancedAuth__form__inputs-container">
            <input
              className="EnhancedAuth__form__inputs-container__input"
              type="text"
              value={userFirstName}
              onChange={onFirstNameChange}
              placeholder={placeholderFirstName}
            />
            <input
              className="EnhancedAuth__form__inputs-container__input"
              type="text"
              value={userLastName}
              onChange={onLastNameChange}
              placeholder={placeholderLastName}
            />
          </div>
          <DidYouKnow />
          <footer className="EnhancedAuth__footer">
            {isLoading ? (
              <Loader />
            ) : (
              <button className="EnhancedButton-primary Button-large Fill-width">continue</button>
            )}
          </footer>
        </form>
      </div>
    </article>
  )
}
