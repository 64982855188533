import 'react-phone-number-input/style.css'
import '../EnhancedAuth.scss'

import type { FormEventHandler, JSX } from 'react'
import type { E164Number } from 'libphonenumber-js/types'

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'

import Loader from '../../Loader/Loader'
import { FirebaseErrorCodes } from '../../../types/firebase-error-codes.enum'
import { AuthStepName } from '../../../types/auth-step-name.enum'
import ThreeStrokes from '../../../assets/svg/ThreeStrokes.svg'
import { Plan } from '../../../__generated__/graphql'

import { WhatIsPie } from './WhatIsPie'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { channelIdToPlanId } from '../../../util/channel-id-to-plan-id'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'

const warnOnlyFirebaseCodes = [FirebaseErrorCodes.invalidPhoneNumber, FirebaseErrorCodes.networkRequestFailed]

export interface EnhancedAuthPhoneNumberProps {
  setAuthStep: (step: AuthStepName) => void
  setPhoneNumberProp: (phoneNumber: string) => void
  plan: Plan | null
}

export function EnhancedAuthPhoneNumber({
  setAuthStep,
  setPhoneNumberProp,
  plan,
}: EnhancedAuthPhoneNumberProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const routeParams = useParams()
  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)
  const location = useLocation()
  useEffect(() => {
    createMixpanelEvent(MixpanelEvent.viewSignUp, {
      pathname: location.pathname,
      platform: 'web',
      planId: planId ?? '',
    })
  }, [planId, location])

  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')

  useEffect(() => {
    if (errorCode) {
      const timer = setTimeout(() => {
        setErrorCode('')
      }, 5000)

      return () => clearTimeout(timer)
    } else {
      return setErrorCode('')
    }
  }, [errorCode])

  useEffect(() => {
    const auth = getAuth()
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      })
    }
  }, [])

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    setIsLoading(true)
    const appVerifier = window.recaptchaVerifier
    const auth = getAuth()
    appVerifier.verify().then(() => {
      signInWithPhoneNumber(auth, String(phoneNumber), appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          setPhoneNumberProp(String(phoneNumber))
          setAuthStep(AuthStepName.verificationCode)
          setIsLoading(false)
        })
        .catch((error) => {
          setErrorCode(error.code)
          if (warnOnlyFirebaseCodes.includes(error.code)) {
            console.error('Issue sending SMS code to user!', { error, phoneNumber })
          } else {
            console.error('Could not send SMS code to user!', { error, phoneNumber })
          }
          setIsLoading(false)
        })
    })
  }
  return (
    <article className="EnhancedAuth">
      <header className="EnhancedAuth__header">
        <div className="EnhancedAuth__rock_on">rock on!</div>
        <img src={ThreeStrokes} alt="three brush strokes" />
        <div className="EnhancedAuth__rsvp">
          {plan ? (
            <div>to rsvp and get all the {plan.title} important updates, create your pie account.</div>
          ) : (
            <div>create or log in to your pie account.</div>
          )}
        </div>
      </header>
      <div className="EnhancedAuth__main">
        <form className="EnhancedAuth__form" onSubmit={handleSubmit}>
          <label>
            <PhoneInput
              className="EnhancedAuth__form__input-wrapper"
              countrySelectProps={{ unicodeFlags: true }}
              countryCallingCodeEditable={false}
              placeholder="phone number"
              defaultCountry="US"
              value={phoneNumber}
              onChange={setPhoneNumber}
              countries={['US', 'GB', 'CZ', 'CA', 'MX', 'PR', 'VI', 'GU', 'AS', 'MP', 'BR', 'PL']}
            />
          </label>
          <div id="recaptcha-container" className="EnhancedAuth__recaptcha"></div>
          <WhatIsPie />
          <footer className="EnhancedAuth__footer">
            {errorCode === FirebaseErrorCodes.invalidPhoneNumber && (
              <p className="EnhancedAuth__phonenumber__form__error">Please enter a valid phone number.</p>
            )}
            {errorCode && errorCode !== FirebaseErrorCodes.invalidPhoneNumber && (
              <p className="EnhancedAuth__phonenumber__form__error">We were unable to send an SMS.</p>
            )}
            {isLoading ? (
              <Loader />
            ) : (
              <button className="EnhancedButton-primary Button-large Fill-width">accept & continue</button>
            )}
          </footer>
        </form>
        <p className="EnhancedAuth__form__terms">
          by pressing accept and continue you agree to pie's{' '}
          <a className="EnhancedAuth__form__terms__link" href="https://www.getpie.app/terms-of-service" target="_blank">
            terms of service
          </a>
          {' and '}
          <a className="EnhancedAuth__form__terms__link" href="https://www.getpie.app/privacy-policy" target="_blank">
            privacy policy
          </a>
          , and consent to receive text messages from pie. message and data rates may apply.
        </p>
      </div>
    </article>
  )
}
