import { JSX, FormEventHandler, useRef, useEffect } from 'react'

import { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

import Loader from '../../Loader/Loader'
import { store } from '../../../store/store'
import { Plan } from '../../../__generated__/graphql'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { channelIdToPlanId } from '../../../util/channel-id-to-plan-id'
import { createMixpanelEvent } from '../../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../../types/mixpanel-event.enum'

export interface EnhancedAuthPictureProps {
  plan: Plan | null
}

// business logic left as-is in this component
export function EnhancedAuthPicture({ plan }: EnhancedAuthPictureProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const routeParams = useParams()
  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)
  const location = useLocation()
  useEffect(() => {
    createMixpanelEvent(MixpanelEvent.viewSignUpAddPHoto, {
      pathname: location.pathname,
      platform: 'web',
      planId: planId ?? '',
    })
  }, [planId, location])

  const authToken = useSelector((state: any) => state.authToken)
  const [userPic, setUserPic] = useState('')
  const [picPreview, setPicPreview] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: any) => {
    const files = e.target.files
    const userPic = files[0]
    setError(null)
    setUserPic(userPic)
    if (userPic) {
      setPicPreview(URL.createObjectURL(userPic))
    }
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    if (!userPic || isLoading) {
      return
    }
    setIsLoading(true)
    const formData = new FormData()
    formData.append('file', userPic)
    axios
      .post(`${process.env.REACT_APP_API_HOST}/users/profile-picture/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken ? `Bearer ${authToken}` : '',
        },
      })
      .then((res) => {
        store.dispatch({ type: 'user/upsert', user: res.data })
      })
      .catch((err) => {
        console.error('Could not upload picture', err)
        setError(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <article className="EnhancedAuth">
      <header className="EnhancedAuth__header">
        <h1>put a face to your name</h1>
        <p>
          {plan != null && (
            <>
              {`help ${plan.ownerUser.firstName} and others recognize you!`} <br />
            </>
          )}
          add a photo to connect more easily and ensure a safer, trusted community.
        </p>
      </header>
      <div className="EnhancedAuth__main">
        <form className="EnhancedAuth__form" onSubmit={handleSubmit}>
          <div className="EnhancedAuth__form__pic">
            <label htmlFor="file-input" className="EnhancedAuth__form__file-input-label">
              <input
                ref={fileInputRef}
                type="file"
                className="EnhancedAuth__form__file-input-label__input"
                accept="image/*"
                onChange={handleChange}
              />
              {userPic && (
                <img
                  src={picPreview}
                  className="Fill-height Fill-width EnhancedAuthPicture__form__pic-preview"
                  alt="a pic of the user"
                />
              )}
            </label>
            <div className="EnhancedAuth__form__file-profile-image">
              <div className={`EnhancedAuth__form__file-profile-image__${userPic ? 'pencil' : 'camera'}`}></div>
            </div>
          </div>
          <footer className="EnhancedAuth__footer">
            {error && (
              <p className="Pink-text">We were unable to upload your picture. Files must be less than 20 MB.</p>
            )}
            {isLoading ? (
              <Loader />
            ) : userPic ? (
              <button className={`Button-large EnhancedButton-primary Fill-width`}>
                {plan ? 'request to join' : 'submit'}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="Button-large EnhancedButton-primary Fill-width"
              >
                add a photo
              </button>
            )}
          </footer>
        </form>
      </div>
    </article>
  )
}
