export enum MixpanelEvent {
  appSession = 'Web App Session',
  viewPlansList = 'Web View Plans List',
  shareLinkOpened = 'Web Share Link Opened',
  viewPlan = 'View Plan',
  inviteTabClicked = 'Web Invite Tab Clicked',
  morePlansForYouLoaded = 'Web More Plans For You Loaded',
  inviteFriendsClicked = 'Web Invite Friends Clicked',
  clickMakePlanButton = 'Web Click Make Plan Button',
  viewSignUp = 'View SignUp',
  viewSignUpAddName = 'View SignUp Add Name',
  viewSignUpAddPHoto = 'View SignUp Add Photo',
}
