import './ChannelDetail.scss'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export const ChannelDetail = ({ planDescription }: { planDescription: string }) => {
  const [descriptionIsExpanded, setDescriptionIsExpanded] = useState(true)
  const descriptionExpandedRef = useRef<HTMLParagraphElement>(null)
  const windowWidth = useSelector((state: any) => {
    return state.windowWidth
  })

  useEffect(() => {
    if (descriptionExpandedRef.current) {
      const lineHeight = 24
      const descriptionTakesUpMoreThanThreeLines = descriptionExpandedRef.current.offsetHeight / lineHeight > 3
      setDescriptionIsExpanded(!descriptionTakesUpMoreThanThreeLines)
    }
  }, [planDescription, windowWidth])

  const handleShowMore = () => {
    setDescriptionIsExpanded(true)
  }

  return (
    <div className="ChannelDetail">
      {descriptionIsExpanded && (
        <p className="ChannelDetail__container" ref={descriptionExpandedRef}>
          {planDescription}
        </p>
      )}
      {!descriptionIsExpanded && <p className="ChannelDetail__container--collapsed">{planDescription}</p>}
      {!descriptionIsExpanded && (
        <p className="ChannelDetail__see-more-button" onClick={handleShowMore}>
          see more
        </p>
      )}
    </div>
  )
}
